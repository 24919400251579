import * as React from "react"
import PropTypes from "prop-types"
// import Helmet from "react-helmet"

import Navbar from "./Globals/Navbar"
import Footer from "./Globals/Footer"
import "./bootstrap.min.css"
import "./layout.css"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

const Layout = ({ children }) => {
  // require("slick-carousel/slick/slick.css")
  // require("slick-carousel/slick/slick-theme.css")
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
    // <>
    // <Helmet>
    //   <Navbar />
    //   {children}
    //   <Footer />
    //   <link
    //     rel="stylesheet"
    //     type="text/css"
    //     charset="UTF-8"
    //     href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
    //   />{" "}
    //   <link
    //     rel="stylesheet"
    //     type="text/css"
    //     href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
    //   />{" "}
    // </Helmet>
    // </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

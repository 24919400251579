import React, { Component } from "react"
import { Link } from "gatsby"
import { IconContext } from "react-icons"
import {
  FaFacebook,
  FaInstagram,
  FaYelp,
  FaTripadvisor,
  FaList,
  FaRegWindowClose,
} from "react-icons/fa"
import { GrMenu } from "react-icons/gr"
import Logo from "../../images/svg/CSlogo.inline.svg"
import Logo3 from "../../images/svg/CSlogo3.inline.svg"

import Modal from "./Modal"

export default class NavbarScroll extends Component {
  constructor(props) {
    super(props)
    this.listener = null
    this.instance = React.createRef(null)
    this.state = {
      status: "top",
      navbarOpen: false,
      isCurrent: false,
      modal: false,
      socialLinks: false,
      click: false,
      scroll: false,
      css: "collapse navbar-collapse",
      navCss: "navbar-mainz",
      links: [
        {
          id: 1,
          path: "/location",
          text: "HOURS & LOCATION",
        },
        {
          id: 2,
          path: "/menu",
          text: "MENUS",
        },
        {
          id: 3,
          path: "/about",
          text: "ABOUT",
        },
        {
          id: 4,
          path: "/contact",
          text: "CONTACT",
        },
      ],
    }
  }
  focus() {
    console.log("From Navbar = ", this.state.instance.current)
  }
  // isActive() {
  //   this.setState({ isCurrent: true })
  //   // return this.state.isCurrent ? { className: "active" } : null
  // }
  isActive = () => {
    this.setState(() => {
      return { isCurrent: true }
    })
  }
  handleSumbit(e) {
    this.modalClose()
  }
  modalOpen() {
    this.setState({ modal: true })
  }
  modalClose() {
    this.setState({ modal: false })
  }
  navbarHandler = () => {
    return this.state.navbarOpen
      ? this.setState({
          navbarOpen: false,
          css: "collapse navbar-collapse",
          socialLinks: false,
        })
      : this.setState({
          navbarOpen: true,
          css: "collapse navbar-collapse show",
          socialLinks: true,
        })
  }
  componentDidMount() {
    this.listener = document.addEventListener("scroll", e => {
      var scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 400) {
        if (this.state.status !== "amir") {
          this.setState({ status: "amir", scroll: true })
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top", scroll: false })
        }
      }
    })
  }
  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener)
  }
  refreshPage() {
    window.location.reload(false)
  }
  render() {
    return (
      <header className="bg-dark navbar-mainz">
        <div className="container">
          <nav
            active={this.state.scroll}
            className="navbar navbar-expand-lg navbar-dark"
          >
            <div className="cs-logo-container navbar-brand">
              <Link to="/">
                <Logo className="cs-logo" />
              </Link>
            </div>
            <div className="justify-content-center">
              <button
                className="navbar-toggler"
                type="button"
                onClick={this.navbarHandler}
              >
                {this.state.navbarOpen ? (
                  <span>
                    <FaRegWindowClose
                      style={{ color: "white", fontSize: "28px" }}
                    />
                  </span>
                ) : (
                  <span>
                    <FaList style={{ color: "white", fontSize: "28px" }} />
                  </span>
                )}

                {/* <span className="navbar-toggler-icon"></span> */}
              </button>
            </div>

            <div className={this.state.css}>
              <ul className="navbar-nav ms-auto navbar-menuz bg-dark">
                {this.state.links.map(link => {
                  return (
                    <li key={link.id}>
                      <Link
                        to={link.path}
                        className="nav-link"
                        style={{
                          color:
                            this.state.status !== "top" ? "white" : "white",
                        }}
                        activeClassName="user-link"
                      >
                        <span className="navbar-styles">{link.text}</span>
                      </Link>
                    </li>
                  )
                })}
                {/* {console.log(this.state.isCurrent)} */}
                <li>
                  <div ref={this.instance}>
                    <button
                      className="btn btn-brand text-uppercase online-order-button-nav"
                      onClick={e => this.modalOpen(e)}
                    >
                      <span className="online-order-span navbar-styles">
                        Order Online
                      </span>
                    </button>
                  </div>
                </li>
                <div style={this.state.socialLinks ? {} : { display: "none" }}>
                  <div className="pt-4">
                    <p>Connect with us online!</p>
                  </div>
                  <div className="social-links-navbar p-2">
                    {/* <div id="" className="fb-circle"> */}
                    <a
                      href="https://www.facebook.com/crabstationodessa"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-links-navbar-link facebook"
                    >
                      {/* <FaFacebook className="fb-circle" /> */}
                      <FaFacebook />
                    </a>
                    {/* </div> */}
                    <a
                      href="https://www.instagram.com/crabstationodessa/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-links-navbar-link instagram large"
                    >
                      {/* <FaInstagram /> */}
                    </a>
                    <a
                      href="https://www.yelp.com/biz/crab-station-and-oyster-bar-odessa-odessa"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-links-navbar-link yelp"
                    >
                      <FaYelp />
                    </a>
                    <a
                      href="https://www.tripadvisor.com/Restaurant_Review-g56385-d22916834-Reviews-The_Crab_Station-Odessa_Texas.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-links-navbar-link trip-advisor"
                    >
                      <FaTripadvisor />
                    </a>
                  </div>
                  <div className="d-flex mx-auto justify-items-center">
                    <Logo3 />
                  </div>
                </div>
                <div id="modal">
                  <Modal
                    show={this.state.modal}
                    setShowModal={e => this.modalClose(e)}
                  />
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

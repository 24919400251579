import React, { useRef } from "react"

// import BackgroundImageTitle from "../components/Globals/BackgroundImageTitle"
import modalImage from "../../images/food-selection.jpeg"
import OnlineOrderBtn from "./OnlineOrder"
import Portal from "./Portal"

const Modal = ({
  handleClose,
  show,
  close,
  children,
  showModal,
  setShowModal,
}) => {
  const showHideClassName = show ? "modal d-block" : "d-none"
  // const instance = useRef(null)
  const modalRef = useRef(null)
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false)
    }
  }
  return (
    <Portal>
      <div className={showHideClassName}>
        {show ? (
          <div
            ref={modalRef}
            id="modal-portal"
            className="modal-background"
            onClick={closeModal}
          >
            <div
              className="modal-wrapper"
              style={{
                transform: show ? "translateY(0vh)" : "translateY(-100vh)",
                opacity: show ? "1" : "0",
                backgroundImage: `url(${modalImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center 1rem",
              }}
            >
              <div className="modal-header">
                <p>Online Order Disclaimer </p>
                <span
                  onClick={() => setShowModal(false)}
                  className="close-modal-btn"
                >
                  x
                </span>
              </div>
              <div className="modal-content">
                <div className="modal-body">
                  {/* Below is for ACTIVE online order */}
                  <p>
                    Preparation of your order will begin immediately after your
                    order has been confirmed, unless stated otherwise.{" "}
                    <strong className="modal-inside-span">
                      We cannot accept cancellations once your order has been
                      confirmed
                    </strong>{" "}
                    with the restaurant or third party. We strive to prepare and
                    package orders to preserve the quality and freshness of our
                    food. To help ensure this,{" "}
                    <strong className="modal-inside-span">
                      please arrive at the scheduled time confirmed by the
                      restaurant.
                    </strong>{" "}
                    If you are going to arrive after the confirmed pick-up time,
                    please provide notice 15 minutes prior to the new pick up
                    time.{" "}
                    <strong className="modal-inside-span">
                      ALL SALES ARE FINAL; NO REFUNDS
                    </strong>{" "}
                    even if the order was not picked up.
                  </p>
                  {/* Below is for disabling Online Orders */}
                  {/* <p>
                    Our Online Order is currently under{" "}
                    <strong className="modal-inside-span">maintenance</strong>.
                    <br />
                    <br /> We are sorry for your incovenience!
                    <br />
                    <br />
                    Click{" "}
                    <strong className="modal-inside-span">
                      <a
                        href="/location"
                        className="hero-text-xred text-decoration-none"
                      >
                        HERE
                      </a>
                    </strong>{" "}
                    to see our hours of operation.
                    <br />
                    <br />
                    Please visit us on{" "}
                    <strong className="modal-inside-span">
                      <a
                        href="https://www.facebook.com/crabstationodessa"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hero-text-xred text-decoration-none"
                      >
                        Facebook
                      </a>
                    </strong>{" "}
                    for updates!
                    <br />
                    <br />
                    <strong className="modal-inside-span">
                      Thank you so much and stay safe.
                    </strong>
                  </p> */}
                  {children}
                  <div>
                    {/* {console.log(
                    `Ref inside Modal.js: ${instance.current} -----------`
                  )} */}
                    <OnlineOrderBtn />
                  </div>

                  <div className="text-end">
                    <small> - Team Crab Station</small>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={() => setShowModal(false)}
                    className="btn-cancel"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Portal>
    // document.querySelector("#modal-portal")
    // document.getElementById("modal-portal")
  )
}

export default Modal

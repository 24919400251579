import React from "react"
import { Link } from "gatsby"
import { FaFacebook, FaInstagram, FaYelp, FaTripadvisor } from "react-icons/fa"
import Logo4 from "../../images/svg/CSlogo4.inline.svg"

export default function Footer() {
  return (
    <div className="footer bg-dark ">
      <div className="container">
        <div className="row footer-row text-uppercase ">
          {/* Column 1 */}
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 footer-c">
            <h4 className="pb-2">Hours</h4>
            <ul className="list-unstyled">
              <li>
                <p className="footer-p">
                  Sunday - Thursday: 12:00 PM - 10:30 PM
                </p>
              </li>
              <li>
                <p className="footer-p">
                  Friday - Saturday: 12:00 PM - 11:00 PM
                </p>
              </li>
              <li>
                <p className="footer-p">
                  <small>Extended Hours on Fight/Game Nights</small>
                </p>
              </li>
            </ul>
            <h6 className="pb-2">Happy Hours</h6>
            <ul className="list-unstyled footer-p">
              <li>
                <p className="footer-p">Monday - Friday: 3PM - 7PM</p>
              </li>
            </ul>
          </div>
          {/* Column 2 */}
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 footer-c">
            <h4 className="pb-2">Location</h4>
            <ul className="list-unstyled">
              <li>
                <p className="footer-p">West Texas:</p>
              </li>
              <li>
                <p className="footer-p">4231 N Dixie Blvd</p>
              </li>
              <li>
                <p className="footer-p">Odessa, TX 79762</p>
              </li>
              <li>
                <div className="cs-logo4-container mx-auto">
                  <Link to="/">
                    <Logo4 className="cs-logo4" />
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          {/* Column 3 */}
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 footer-c">
            <h4 className="pb-2">Contact</h4>
            <ul className="list-unstyled">
              <li>
                <p className="footer-p pb-3">Phone: (432) 614 - 6526</p>
              </li>
              <li>
                <p className="footer-p pb-3">
                  <span className="text-lowercase">
                    contact@crabstationodessatexas.com
                  </span>
                </p>
              </li>
              <li>
                <p className="footer-p">On Social Media:</p>
                <div className="footer-socials-wrap">
                  <div className="footer-social-wrapper">
                    <a
                      href="https://www.facebook.com/crabstationodessa"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-socials-icon-link"
                    >
                      <FaFacebook />
                    </a>
                    <a
                      href="https://www.instagram.com/crabstationodessa/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-socials-icon-link"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      href="https://www.tripadvisor.com/Restaurant_Review-g56385-d22916834-Reviews-The_Crab_Station-Odessa_Texas.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-socials-icon-link"
                    >
                      <FaTripadvisor />
                    </a>
                    <a
                      href="https://www.yelp.com/biz/crab-station-and-oyster-bar-odessa-odessa"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-socials-icon-link"
                    >
                      <FaYelp />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="row">
          <p className="col-sm text-center">
            &copy; 2020 The Crab Station & Oyster Bar
          </p>
        </div>
      </div>
    </div>
  )
}

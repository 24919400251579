import React, { useEffect } from "react"

const OnlineOrder = ({ inst }) => {
  // (GF API Call) create a ref with null as initial value
  // const instance = useRef(null)
  useEffect(() => {
    // create a script tag
    const scriptTag = document.createElement("script")

    // give it a src attribute the link is the link to gloria food
    scriptTag.src = "https://www.fbgcdn.com/embedder/js/ewm2.js"
    scriptTag.defer = true
    scriptTag.async = true

    // var k = instance.current.getElementById("modal")
    var k = document.getElementById("modal-portal")
    // inst.current.appendChild(scriptTag)
    // return () => {
    //   inst.current.removeChild(scriptTag)
    // }

    // we append the script tag to our div instance
    if (k !== null || typeof k !== "undefined") {
      k.appendChild(scriptTag)
    }
    console.log("K is = ", k)
    console.log("scriptTag is = ", scriptTag)
    return () => {
      document.getElementById("modal-portal").removeChild(scriptTag)
    }
  }, [])
  // this runs after our component has rendered

  // setTimeout(function () {
  //   document.getElementById("orderOnlineContainer").removeChild(scriptTag)
  // }, 1500)
  // setTimeout(function () {
  //   instance.current.removeChild(scriptTag)
  // }, 1500)
  // }, [])
  return (
    <div className="hero-buttons">
      <button
        className="btn btn-brand text-uppercase"
        data-glf-cuid="3301939d-d054-4a6a-8567-7c86fe1db46b"
        data-glf-ruid="9a64de6c-b5bb-4bc3-a714-8fcc14e2a648"
      >
        Proceed to Order
      </button>
    </div>
  )
}
export default OnlineOrder
